import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/digital-technology-services.jpg'


const pageTitle = "Digital Technology Services";
const pageSlug = "digital-technology-services";


class DigitalTechnologyServices extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Our services include project and program management, cloud & mobile applications, automation, and workflow. </h6>
        </div>
</section>

<section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>An organization needs wide-ranging skills to successfully implement digital initiatives.  These include customer-centricity and user-friendly applications that are well designed.
                  </h3>
                  <p>Implementing a digital transformation strategy includes the creation of robust end-to-end processes, management of organizational change, and capabilities of technology.</p>
                  <span className="break"></span>
                  <span className="break"></span>
                  <h4>Our expertise in Digital Technology </h4>
                  <p>Our experts understand technology, we offer the following and many more: </p>
                  <h5>Custom Development </h5>
                  <p>Let us give you scalable, easy to use and fast to maintain solutions and design systems. Software development is our specialty at Monera Technologies, and we are always willing to help.</p>
                  <h5>Agile </h5>
                  <p>Our agile approach will provide you with easier, quicker, and cheaper reliable metrics-oriented application delivery.</p>
                  <h5>Analytics</h5>
                  <p>We offer services for data and analytics that go beyond traditional business intelligence boundaries. To improve future growth, we help our customers turn their information into clear and concise insights.</p>
                  <h5>Automation and workflow </h5>
                  <p>Let us analyze your situation and provide you with a relevant roadmap, our solutions and integrations ensure that your marketing operations are functioning smoothly, today and in the future.</p>
                  <h5>Digital Strategy</h5>
                  <p>Your business needs up-to-date approaches to effectively update your digital strategy. In the digital world, a winning business strategy needs much more than just a few ads and a redesign of your website.</p>
                  <h5>Mobile App Development </h5>
                  <p>Our mobile app development will help you transform your business and identify opportunities for growth, innovation and customer experience.</p>
                  <h5>Enterprise Project</h5>
                  <p>Projects and Portfolio Management System and Project Management will allow your projects to comply with your strategic objective. Ensure that solutions are implemented within budget and on time.</p>
                  <h5>Cloud Computing </h5>
                  <p>Let's decide how your company will interact with the cloud. We will recommend a cloud platform that will boost the processes and transform them.</p>
                  

                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default DigitalTechnologyServices
